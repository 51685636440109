import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Markdown from 'markdown-to-jsx';
import ScrollAnimation from 'react-animate-on-scroll';
import { useIntl } from 'react-intl';

import {
  CarouselSlider,
  Layout,
  ReadyToTalk,
  SiteMap,
  SocialButtons,
} from '../../components';
import { ReactComponent as BackIcon } from '../../assets/images/icons/ic_back.svg';

import { useApp } from '../../providers';
import { pageView } from '../../utils/Mautic.util';

import { assetUrl } from '../../helpers/Strapi.helper';

const useCaseFetcher = id => {
  const { api } = useApp();
  React.useEffect(() => {
    pageView();
  }, []);

  const [data, setData] = React.useState({
    case: null,
    error: null,
    isFetching: true,
  });

  React.useEffect(() => {
    if (!id) return;

    const fetchCase = async id => {
      try {
        const response = await api.getCase(id);
        setData({ case: response.data, isFetching: false });
      } catch (error) {
        setData({
          error: error?.message || 'Falha ao consultar artigo.',
          isFetching: false,
        });
      }
    };

    fetchCase(id);
  }, [api, id]);

  return data;
};

const Case = ({ cases }) => {
  const history = useHistory();
  const params = useParams();
  const { case: data, isFetching } = useCaseFetcher(params?.id);
  const { formatMessage } = useIntl();
  const { siteLang: language } = useApp();

  const auxLanguage = language === 'pt-BR' ? 'pt-BR' : 'en';

  const othersCase = React.useMemo(
    () => cases?.filter(report => report.id !== data?.id).slice(0, 4),
    [cases, data],
  );

  const handleGoCases = () => {
    history.push('/cases');
  };

  React.useEffect(() => {
    if (data?.locale === auxLanguage) return;

    const _case = data?.localizations[0].id;
    history.replace(`/case/${_case}`);
  }, [data, history, auxLanguage]);

  return (
    <Layout className="case" showFooter showHeader testID="Article">
      <div className="header-top">
        <div
          className="header-top-cover"
          style={{
            backgroundImage: data?.coverTop
              ? `url(${assetUrl(data.coverTop)}`
              : 'url(/assets/images/bg_fiber.jpg)',
            opacity: isFetching ? 0 : 1,
          }}
        ></div>
      </div>

      <div className="all-cases">
        <div className="header">
          <div className="header-back" onClick={handleGoCases}>
            <div className="header-back-icon">
              <BackIcon className="icon-back" />
            </div>
            <span>{formatMessage({ id: 'pageCaseButtonBack' })}</span>
          </div>

          <div className="header-title">
            <h5>{formatMessage({ id: 'pageCaseTitle' })}</h5>
            <h1>{data?.title}</h1>
            <div className="header-title-icons">
              {data?.content && <SocialButtons data={data} />}
            </div>
          </div>

          {data?.resume ? (
            <div className="header-content">
              <p>{data?.resume}</p>
            </div>
          ) : (
            <div className="header-content">
              <p> {data?.subtitle}</p>
            </div>
          )}

          {data?.content && (
            <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
              <div className="header-paragraph-first">
                <Markdown>{data.content}</Markdown>
              </div>
            </ScrollAnimation>
          )}
        </div>
        <div className="horizontal-divider"></div>
      </div>

      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <div className="other-cases">
          <CarouselSlider
            subtitle={formatMessage({ id: 'pageCaseCarouselSubtitle' })}
            title={formatMessage({ id: 'pageCaseCarouselTitle' })}
            data={othersCase}
            type="casesHome"
          />
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <ReadyToTalk type="small" />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <SiteMap />
      </ScrollAnimation>
    </Layout>
  );
};

Case.propTypes = {
  cases: PropTypes.array,
};

const mapStateToProps = state => ({
  cases: state.cases.data,
});

export default connect(mapStateToProps)(Case);
