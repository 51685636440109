import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useIntl } from 'react-intl';
import { Link, NavLink, useLocation } from 'react-router-dom';

// components
import Button from '../Button';
import LanguageDropdown from '../LanguageDropdown';

// effects
import { useWindowScrollListener } from '../../effects/App.effects';

// others
import Logo from '../../assets/images/logo_bycoders_.svg';

// local
import { useAbsolutePosition } from './Menu.effects';

// providers
import { useApp } from '../../providers/App';

const Menu = ({ hasOpacity, open, testID, toggleSidebar }) => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const { scroll } = useWindowScrollListener();
  const isAbsolute = useAbsolutePosition(location.pathname);
  const isHome = location.pathname === '/';
  const { siteLang } = useApp();

  return (
    <div
      className={cls('menu', {
        absolute: isAbsolute,
        reverse: !isHome,
      })}
    >
      {!open && (
        <div
          data-testid={`${testID}_Header_Mobile`}
          className={cls('header-mobile', {
            absolute: isAbsolute,
          })}
        >
          <Link to="/">
            <div className="logo-mobile">
              <img src={Logo} alt="close" />
            </div>
          </Link>
          <div className="content">
            {isHome ? (
              <LanguageDropdown />
            ) : (
              <Link to="/contact">
                <Button
                  size={siteLang !== 'pt-BR' ? 'medium' : 'large'}
                  type="headerButton"
                >
                  {formatMessage({ id: 'letsTalk' })}
                </Button>
              </Link>
            )}
            <div className="burguer-button" onClick={toggleSidebar}>
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      )}

      <div className="menu-content">
        <div
          data-testid={`${testID}_Header`}
          className={cls(
            `header-open-${open}`,
            `header-scroll-${scroll}`,
            `header-opacity-${hasOpacity}`,
            {
              absolute: isAbsolute,
            },
          )}
        >
          <Link to="/">
            <div className="logo">
              <img
                data-testid={`${testID}_Header_Image`}
                src={Logo}
                alt="logo"
              />
            </div>
          </Link>
          <ul
            className={cls('menu-links', {
              reverse: !isHome,
            })}
          >
            <li
              className={cls({
                reverse: !isHome,
              })}
            >
              <NavLink
                className={cls({
                  reverse: !isHome,
                })}
                to="/services"
              >
                {formatMessage({ id: 'menuHeader1' })}
              </NavLink>
            </li>
            <li
              className={cls({
                reverse: !isHome,
              })}
            >
              <NavLink
                className={cls({
                  reverse: !isHome,
                })}
                to="/cases"
              >
                {formatMessage({ id: 'menuHeader2' })}
              </NavLink>
            </li>

            <li
              className={cls({
                reverse: !isHome,
              })}
            >
              <NavLink
                className={cls({
                  reverse: !isHome,
                })}
                to="/careers"
              >
                {formatMessage({ id: 'menuHeader5' })}
              </NavLink>
            </li>

            <li
              className={cls({
                reverse: !isHome,
              })}
            >
              <NavLink
                className={cls({
                  reverse: !isHome,
                })}
                to="/about"
              >
                {formatMessage({ id: 'menuHeader4' })}
              </NavLink>
            </li>

            <li>
              <Link to="/contact">
                <Button
                  size={siteLang !== 'pt-BR' ? 'medium' : 'large'}
                  type="headerButton"
                >
                  {formatMessage({ id: 'letsTalk' })}
                </Button>
              </Link>
            </li>
            <li className="toggle">
              <LanguageDropdown type={isHome ? 'primary' : 'secondary'} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  hasOpacity: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  testID: PropTypes.string.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

Menu.defaultProps = {
  hasOpacity: false,
  testID: 'Menu',
};

export default Menu;
