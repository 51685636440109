import React from 'react';

import { ReactComponent as ConsultingIcon } from '../../assets/images/consulting.svg';
import { ReactComponent as DesignIcon } from '../../assets/images/design.svg';
import { ReactComponent as DevelopmentIcon } from '../../assets/images/development.svg';
import { ReactComponent as ServiceIcon } from '../../assets/images/outsourcing.svg';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const OurServices = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="our-services-content">
      <div className="our-services-container">
        <Link to="/services" className="links">
          <div className="service">
            <div className="service-image">
              <div className="service-image-content">
                <ConsultingIcon stroke="#898989" />
              </div>
            </div>
            <div className="service-infos">
              <div className="service-title">
                <span>{formatMessage({ id: 'consultingTitle' })}</span>
              </div>
              <div className="service-subtitle">
                <span>{formatMessage({ id: 'consultingSubTitle' })}</span>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/services" className="links">
          <div className="service reverse">
            <div className="service-image reverse">
              <div className="service-image-content reverse">
                <DesignIcon stroke="#898989" />
              </div>
            </div>
            <div className="service-infos">
              <div className="service-title reverse">
                <span>{formatMessage({ id: 'designTitle' })}</span>
              </div>
              <div className="service-subtitle reverse">
                <span>{formatMessage({ id: 'designSubTitle' })}</span>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/services" className="links linebelow">
          <div className="service linebelow">
            <div className="service-image">
              <div className="service-image-content">
                <DevelopmentIcon stroke="#898989" />
              </div>
            </div>

            <div className="service-infos">
              <div className="service-title">
                <span>{formatMessage({ id: 'developmentTitle' })}</span>
              </div>
              <div className="service-subtitle">
                <span>{formatMessage({ id: 'developmentSubTitle' })}</span>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/services" className="links linebelow">
          <div className="service reverse">
            <div className="service-image reverse">
              <div className="service-image-content reverse marginbottom">
                <ServiceIcon stroke="#898989" />
              </div>
            </div>
            <div className="service-infos aligncenter">
              <div className="service-title reverse">
                <span>{formatMessage({ id: 'serviceTitle' })}</span>
              </div>
              <div className="service-subtitle reverse">
                <span>{formatMessage({ id: 'serviceSubTitle' })}</span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default OurServices;
