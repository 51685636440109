import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  About,
  Article,
  Articles,
  Careers,
  Case,
  Cases,
  Contact,
  Home as HomePage,
  NotFound,
  PrivacyPolice,
  Services,
} from '.';
import Vacancy from './Vacancy/Vacancy';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);
const Routes = () => {
  return (
    <>
      <Router>
        <ScrollToTop>
          <TransitionGroup>
            <CSSTransition classNames="slide" timeout={3000}>
              <Switch>
                <Route path="/" exact component={HomePage} title="Home" />
                <Route
                  path="/articles"
                  exact
                  component={Articles}
                  title="Articles"
                />
                <Route
                  path="/article/:id"
                  exact
                  component={Article}
                  title="Article"
                />
                <Route path="/cases" exact component={Cases} title="Cases" />
                <Route path="/case/:id" exact component={Case} title="Case" />
                <Route path="/about" exact component={About} title="About" />
                <Route
                  path="/services"
                  exact
                  component={Services}
                  title="Services"
                />
                <Route
                  path="/careers"
                  exact
                  component={Careers}
                  title="Careers"
                />
                <Route
                  path="/careers/vacancy/:id"
                  exact
                  component={Vacancy}
                  title="Vacancy"
                />
                <Route
                  path="/contact"
                  exact
                  component={Contact}
                  title="Contact"
                />
                <Route
                  path="/privacy-police"
                  exact
                  component={PrivacyPolice}
                  title="PrivacyPolice"
                />
                <Route component={NotFound} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </ScrollToTop>
      </Router>
    </>
  );
};
export default Routes;
