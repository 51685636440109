import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';

import {
  Articles,
  CarouselSlider,
  Clients,
  // CeoCompany,
  HomeVariants,
  Layout,
  NewsLetter,
  OurServices,
  ReadyToTalk,
  SiteMap,
} from '../../components';

import { useApp } from '../../providers';
import { ArticlesActions, CasesActions, ClientsActions } from '../../store';
import { pageView } from '../../utils/Mautic.util';

const Home = ({ articles, cases, clients }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { api, siteLang: language } = useApp();

  React.useEffect(() => {
    pageView();
  }, []);

  React.useEffect(() => {
    dispatch(ArticlesActions.getPrioritizedArticles(api, { language }));
    dispatch(CasesActions.getCases(api, { language }));
    dispatch(ClientsActions.getClients(api));
  }, [api, language, dispatch]);

  // Testando..

  return (
    <Layout className="home" showHeader showFooter testID="Home">
      <HomeVariants />
      <OurServices />
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <CarouselSlider
          subtitle={formatMessage({ id: 'homeCaseSubTitle' })}
          title={formatMessage({ id: 'homeCaseTitle' })}
          data={cases}
          type="casesHome"
        />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <Clients clients={clients} />
      </ScrollAnimation>
      {/* <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <CeoCompany />
      </ScrollAnimation> */}
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <Articles data={articles} />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <ReadyToTalk
          type="default"
          getInTouch={formatMessage({ id: 'readtyToTalkTitle' })}
        />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <NewsLetter />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <SiteMap />
      </ScrollAnimation>
    </Layout>
  );
};

Home.propTypes = {
  articles: PropTypes.array,
  cases: PropTypes.array,
  clients: PropTypes.array,
};

const mapStateToProps = state => ({
  articles: state.articles.prioritizedData,
  cases: state.cases.data,
  clients: state.clients.data,
});

export default connect(mapStateToProps)(Home);
