import { LOCALES } from '../locales';

const EnUs = {
  [LOCALES.ENGLISH]: {
    hello: 'Hello, {name}!',
    homeTitle: 'bespoke solutions',
    homeTitle2: 'to real-world problems',
    homeSubTitle:
      'Our purpose is to build tailor-made technological solutions ',
    homeSubTitle2: 'for digital transformation projects, focusing on ',
    homeSubTitle3: 'user experience.',
    letsTalk: 'let’s talk',
    seeMoreButton: 'see more',
    sendFormButton: 'SEND',
    homeClientsTitle: 'CLIENTS',
    homeClientsText: 'we’re proud to partner with clients on ',
    homeClientsText2: 'meaningful solutions',
    homeCaseTitle: 'cases',
    homeCaseSubTitle: 'meet our',
    seeAllCases: 'see all cases',
    seeAllArticles: 'see all articles',
    consultingTitle: 'Consulting',
    consultingSubTitle: 'Corporate innovation and digital transformation',
    designTitle: 'design UX/UI',
    designSubTitle: 'user experience for digital products',
    developmentTitle: 'development',
    developmentSubTitle: 'Web and mobile technologies',
    serviceTitle: 'Outsourcing',
    serviceSubTitle: 'IT outsourcing | Team-as-a-service',
    homeCeoBio1: 'Software Engineer with more than 15 years ',
    homeCeoBio2:
      'experience in the technology area, he worked for Datasul and TOTVS, ',
    homeCeoBio3: 'and was the co-founder of the startups Pipefy and minestore',
    homeCooBio1:
      'Economist, entrepreneurial executive with more than 20 years of ',
    homeCooBio2:
      'experience as partner, director and advisor of companies and ',
    homeCooBio3: 'startups in the health, retail and technology industries.',
    readtyToTalkTitle: 'get in touch',
    readtyToTalkContent: 'Ready to talk about your project?',
    newsLetterSubtitle: 'Subscribe to our',
    newsLetterTitle: 'Newsletter',
    newsLetterContent:
      'and receive information about digital transformation and news from bycoders',
    newsLetterTerms1: 'By entering your email address you agree to our',
    newsLetterTerms2: 'Terms of Use',
    newsLetterTerms3: ' and ',
    newsLetterTerms4: 'Privacy Policy',
    newsLetterTerms5: ' and consent to receive emails from bycoders.',
    siteMapCol1Title: 'website',
    siteMapCol1Content1: 'Services',
    siteMapCol1Content2: 'Cases',
    siteMapCol1Content3: 'About us',
    siteMapCol1Content4: "Let's talk",
    siteMapCol1Content5: 'Careers',
    siteMapCol1Content6: 'Articles',
    siteMapCol2Title: 'Remote offices',
    siteMapCol2Content1: 'Brazil',
    siteMapCol2Content2: 'Portugal',
    siteMapCol3Title: 'LEGAL CONDITIONS AND PRIVACY',
    siteMapCol3Content1: 'Legal',
    siteMapCol3Content2: 'Privacy Policy',
    menuHeader1: 'Services',
    menuHeader2: 'Cases',
    menuHeader3: 'bycoders_',
    menuHeader4: 'About us',
    menuHeader5: 'Careers',
    menuHeader6: 'Articles',
    menuHeader7: 'Contact',
    hoverReadMore: 'read more',
    servicePageSubtitle: 'development of tailor-made technological solutions',
    servicePageTitle:
      'We are experts in accelerating businesses and achieving results',
    servicePageContent:
      'Consulting, design and software factory services are provided based on strategic intelligence, focused on digital transformation and user experience, for the co-creation of tailor-made solutions for each client.',
    servicePageConsulting1:
      "In the era of digital transformation, it's not enough to simply create software. We understand that real change begins by diving into the specific challenges of each business. That's why we offer specialized consultancy to guide your company on the journey towards the future, using technology as a catalyst.",
    servicePageConsulting2:
      'Our consultancy, backed by research and experimentation, delivers innovative solutions that empower your business through technology.',
    servicePageDesign:
      'Each project is unique and to ensure the usability of our applications we have a team of experts in design of digital products. All decisions are made based on the user’s journey, which goes far beyond aesthetics. We have developed a framework that brings together a set of methods and tools for determining the whys, making a diagnosis and proposing the solution, thus avoiding surprises throughout the process and ensuring the quality of the final delivery.',
    servicePageDevelopment:
      'As a software factory, we are specialists in developing custom software and applications, using various technologies such as: Ruby on Rails, React, React Native, Node js, Python and .NET CORE, among others',
    servicePageService:
      "We assemble exceptional teams, tailored to client projects. Each allocated professional receives constant support from our team specializing in People and Culture (soft skills), Tech Leads and bycoders_'s own CTO. Furthermore, our CTO interacts personally with client project managers at least once a month, providing a unique connection and enhancing results.",
    casesPageTitle: 'client cases',
    casesPageSubtitle:
      'Meet some of our clients and find out how we contributed to these companies!',
    aboutPageSubtitle: 'Learn more about',
    aboutPageTitle: 'bycoders_',
    aboutPageSocialMedia: 'Follow us on social media',
    aboutPageContent1:
      'We are a software house, with a startup spirit, which has tailor-made technological solutions focused on user experiences, ensuring that they happen at the right time.',
    aboutPageContent2:
      'We see technology as a means to achieve goals, never as an end in itself, that is why our digital transformation process combines tools, methods and people - indispensable components to obtain high-performance results.',
    aboutPagePurposeTitle: 'Purpose',
    aboutPagePurposeContent:
      'Our purpose is to build tailor-made technological solutions for digital transformation projects with a focus on user experience.',
    aboutPageVisionTitle: 'Vision',
    aboutPageVisionContent:
      'To be internationally recognized as a Brazilian Software House that is renowned for its work in the field of digital transformation, by 2026.',
    meetFounder: 'Meet the founders',
    careerPageSubtitle: 'BE A coder_!',
    careerPageTitle: 'CAREERS',
    careerPageOurTeamTitle: 'Our team',
    careerPageOurTeam1:
      'We are experts in technology and passionate about what we do. Innovation is part of our daily routine. An agile and multidisciplinary team composed of software engineers, consultants, web and mobile developers and web designers that are experts in User Experience and User Interface.',
    careerPageOurTeam2:
      'As a gift for the 1-year anniversary of our foundation, we received the Great Place to Work® seal with a score of 98, which attests to our concern for taking care of our coders_, as we refer to the people that work with us.',
    careerPageOurTeam3: 'Consideration, results and learning are our values!',
    careerPageWorkAnywhereTitle: 'Work from anywhere',
    careerPageWorkAnywhere1:
      'We understand that the opportunity for the selection of talents has no borders and that is why, since its foundation, bycorders_ has been 100% virtual. ',
    careerPageWorkAnywhere2:
      'The selection, hiring and work process methods are all formatted for remote management, but in a way that everyone feels close, even if they live in different cities or countries.',
    careerPageWorkAnywhere3:
      'We have strong values, we like challenges and we believe that our business is made by people (coders_) for people (Clients). That is why we like those that are always keen to learn, enjoy sharing and know how to manage themselves.',
    careerPageWorkAnywhere4:
      'If you saw yourself as part of all that, Welcome aboard!',
    careerPagePosition: 'Opportunities',
    articlesPageTitle: 'articles',
    articlesPageSubtitle: 'Relevant content to build a better business world.',
    pageContactTitle1: 'Let’s talk',
    pageContactSubtitle: 'Ready to',
    pageContactTitle2: 'INNOVATE WITH US?',
    pageContactPhone1: 'You may contact us by:',
    pageContactPhone2: 'phone or WhatsApp at ',
    pageContactPhone3: 'or by email at contato@bycoders.com.br',
    pageContactFormTitle:
      'Or, if you prefer, we can get in touch with you, but, in this case, we will need some information from you.',
    pageContactFormSubtitle:
      'We respond within 24 hours after receiving the message, considering business days only.',
    pageContactFormQt1Title: 'How can we help you?',
    pageContactFormQt1Option1: 'New digital product development',
    pageContactFormQt1Option2: 'Design of UX and UI solutions',
    pageContactFormQt1Option3: 'Digital transformation consultancy',
    pageContactFormQt1Option4: 'Hunting and allocation of IT professionals',
    pageContactFormQt1Option5: 'Others',
    pageContactFormQtName: 'What is your name?',
    pageContactFormQtCompany: 'What is the name of your company / project?',
    pageContactFormQtEmail: 'What is your e-mail address?',
    pageContactFormQtNumber: 'Would you like to leave a contact phone number?',
    pageContactFormQtContactTitle: 'How should we contact you?',
    pageContactFormQtContactOp1: 'WhatsApp',
    pageContactFormQtContactOp2: 'Phone',
    pageContactFormQtContactOp3: 'Email',
    pageContactFormQtBudgetTitle: 'What is your budget?',
    pageContactFormQtBudgetOp1: 'Less than $ 25,000.00',
    pageContactFormQtBudgetOp2: '$25,000.00 - $50,000,00',
    pageContactFormQtBudgetOp3: '$50,000.00 - $100,000.00',
    pageContactFormQtBudgetOp4: '$100,000.00 or more',
    pageCaseCarouselTitle: 'cases',
    pageCaseCarouselSubtitle: 'other bycoder’s',
    pageArticleCarouselTitle: 'articles',
    pageArticleCarouselSubtitle: 'other articles',
    pageArticleTitle: 'article',
    pageCaseTitle: 'client case',
    pageCaseButtonBack: 'all the cases',
    pageArticleButtonBack: 'all the articles',
    pagePrivacyPoliceTitle: 'Privacy Policy for bycoders_ Website',
    pagePrivacyPoliceContent1: 'At',
    pagePrivacyPoliceContent2: 'bycoders_',
    pagePrivacyPoliceContent3: 'Website, accessible from',
    pagePrivacyPoliceContent24: 'https://www.bycoders.co',
    pagePrivacyPoliceContent25:
      'one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Bycoders Website and how we use it.',
    pagePrivacyPoliceContent4:
      'If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.',
    pagePrivacyPoliceSubtitle1: 'Log Files',
    pagePrivacyPoliceContent5:
      'Bycoders Website follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Privacy Policy Generator.',
    pagePrivacyPoliceSubtitle2: 'Cookies and Web Beacons',
    pagePrivacyPoliceContent6: 'Like any other website,',
    pagePrivacyPoliceContent7:
      'Website uses ’cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.',
    pagePrivacyPoliceContent8:
      'For more general information on cookies, please read the "What Are Cookies" article on Cookie Consent website.',
    pagePrivacyPoliceSubtitle3: 'Privacy Policies',
    pagePrivacyPoliceContent9:
      'You may consult this list to find the Privacy Policy for each of the advertising partners of',
    pagePrivacyPoliceContent10: 'Website.',
    pagePrivacyPoliceContent11:
      'Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on',
    pagePrivacyPoliceContent12:
      'Website, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.',
    pagePrivacyPoliceContent13: 'Note that',
    pagePrivacyPoliceContent14:
      'Website has no access to or control over these cookies that are used by third-party advertisers.',
    pagePrivacyPoliceSubtitle4: 'Third Party Privacy Policies',
    pagePrivacyPoliceContent15:
      'Bycoders Website’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.',
    pagePrivacyPoliceContent16:
      'You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites. What Are Cookies?',
    pagePrivacyPoliceSubtitle5: 'Children’s Information',
    pagePrivacyPoliceContent17:
      'Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.',
    pagePrivacyPoliceContent26: 'The',
    pagePrivacyPoliceContent18:
      'Website does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.',
    pagePrivacyPoliceSubtitle6: 'Online Privacy Policy Only',
    pagePrivacyPoliceContent19:
      'This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in',
    pagePrivacyPoliceContent20:
      'Website. This policy is not applicable to any information collected offline or via channels other than this website.',
    pagePrivacyPoliceSubtitle7: 'Consent',
    pagePrivacyPoliceContent21:
      'By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.',
    pagePrivacyPoliceContent22:
      'Questions for clarification, contact our responsible:',
    pagePrivacyPoliceContent23: 'Vanildo Prates - contato@bycoders.com.br',
    subscribeButton: 'subscribe',
    messageSawAllCases: 'You saw all cases!',
    messageSawAllArticles: 'You saw all articles!',
    msgNotFound: 'Ops! Sorry, we couldn’t find the page.',
    buttonNotFound: 'go to home',
    newsletterRequiredField: 'Required field',
    newsletterValidField: 'Enter a valid email address.',
    newsletterSuccess: 'Registered Email. Thanks for signing up!',
    contactConfirmationTitle: 'Thank you for contacting us!',
    contactConfirmationSubtitle:
      'We’ll get back to you in the next 24h, considering weekday.',
    contactConfirmationParagraph:
      'Meanwhile, how about reading one of our articles?',
    careerPageTitleOurOpportunities: 'Our Opportunities',
    careerPageFilterCountry: 'Country',
    careerPageFilterPlace: 'Place',
    careerPageFilterTypeContract: 'Type of contract',
    careerPageFilterArea: 'Area',
    careerPageFilterEnterVancancy: 'Enter your vacancy',
    careerPageApplyVancancy: 'Apply',
  },
};

export default EnUs;
