import React from 'react';
import cls from 'classnames';
import { useIntl } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';

// components
import { Layout, ReadyToTalk, SiteMap } from '../../components';

// effects
import { useWindowResizeListener } from '../../effects/App.effects';

// providers
import { useApp } from '../../providers';

// others
import { ReactComponent as ConsultingIcon } from '../../assets/images/consulting.svg';
import { ReactComponent as DevelopmentIcon } from '../../assets/images/development.svg';
import { ReactComponent as DesignIcon } from '../../assets/images/design.svg';
import { ReactComponent as OutsourcingIcon } from '../../assets/images/outsourcing.svg';
import { pageView } from '../../utils/Mautic.util';

const Services = () => {
  const { formatMessage } = useIntl();
  const { width } = useWindowResizeListener();
  const { siteLang } = useApp();

  React.useEffect(() => {
    pageView();
  }, []);

  return (
    <Layout className="services" showFooter showHeader testID="Services">
      <div className="all-services">
        <div className="header">
          <div className="images">
            {siteLang === 'pt-BR' ? (
              <iframe
                src="https://www.youtube.com/embed/u3i7Qlh4l5c?si=helG89s3QmYx8HKm"
                title="bycoders_"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className="iframe-services"
              ></iframe>
            ) : (
              <iframe
                src="https://www.youtube.com/embed/u3i7Qlh4l5c?si=helG89s3QmYx8HKm"
                title="bycoders_"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className="iframe-services"
              ></iframe>
            )}
          </div>
          <div className="header-container">
            <div className="header-title">
              <h5>{formatMessage({ id: 'readtyToTalkContent' })}</h5>
              <h1>{formatMessage({ id: 'servicePageTitle' })}</h1>
            </div>

            <div className="paragraph">
              <p>{formatMessage({ id: 'servicePageContent' })}</p>
            </div>
          </div>
        </div>

        <div className="works">
          <div className="all-works">
            <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
              <div className="work-content">
                <div className={cls('work', { consulting: true })}>
                  <div className="work-image">
                    <ConsultingIcon stroke="#171616" />
                  </div>
                </div>
                <div className="work-container">
                  <h2>{formatMessage({ id: 'consultingTitle' })}</h2>
                  <span>
                    {formatMessage({ id: 'servicePageConsulting1' })}{' '}
                  </span>
                  <span>{formatMessage({ id: 'servicePageConsulting2' })}</span>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
              <div className={cls('work-content', { reverse: width > 1080 })}>
                <div className={cls('work', { design: true })}>
                  <div className={cls('work-image', { bigger: true })}>
                    <DesignIcon stroke="#898989" />
                  </div>
                </div>
                <div className="work-container">
                  <h2>{formatMessage({ id: 'designTitle' })}</h2>
                  <span>{formatMessage({ id: 'servicePageDesign' })}</span>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
              <div className="work-content">
                <div className={cls('work', { development: true })}>
                  <div className="work-image">
                    <DevelopmentIcon stroke="#5f6061" />
                  </div>
                </div>
                <div className="work-container">
                  <h2>{formatMessage({ id: 'developmentTitle' })}</h2>
                  <span>{formatMessage({ id: 'servicePageDevelopment' })}</span>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
              <div className={cls('work-content', { reverse: width > 1080 })}>
                <div className={cls('work', { design: true })}>
                  <div className={cls('work-image', { bigger: true })}>
                    <OutsourcingIcon stroke="#898989" />
                  </div>
                </div>
                <div className="work-container">
                  <h2>{formatMessage({ id: 'serviceTitle' })}</h2>
                  <span>{formatMessage({ id: 'servicePageService' })}</span>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <ReadyToTalk type="default" getInTouch={'Get in touch'} />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <SiteMap />
      </ScrollAnimation>
    </Layout>
  );
};

export default Services;
