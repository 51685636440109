import React from 'react';
import { useIntl } from 'react-intl';

export const useOptions = () => {
  const { formatMessage } = useIntl();
  return React.useMemo(
    () => ({
      BUDGET: [
        formatMessage({ id: 'pageContactFormQtBudgetOp1' }),
        formatMessage({ id: 'pageContactFormQtBudgetOp2' }),
        formatMessage({ id: 'pageContactFormQtBudgetOp3' }),
        formatMessage({ id: 'pageContactFormQtBudgetOp4' }),
      ],

      CONTACT: [
        {
          field: 'whatsapp',
          label: formatMessage({ id: 'pageContactFormQtContactOp1' }),
        },
        {
          field: 'phone',
          label: formatMessage({ id: 'pageContactFormQtContactOp2' }),
        },
        {
          field: 'email',
          label: formatMessage({ id: 'pageContactFormQtContactOp3' }),
        },
      ],

      HELP_TYPE: [
        formatMessage({ id: 'pageContactFormQt1Option1' }),
        formatMessage({ id: 'pageContactFormQt1Option2' }),
        formatMessage({ id: 'pageContactFormQt1Option3' }),
        formatMessage({ id: 'pageContactFormQt1Option4' }),
        formatMessage({ id: 'pageContactFormQt1Option5' }),
      ],
    }),
    [formatMessage],
  );
};
